var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height pa-0",attrs:{"align":"start","justify":"center"}},[_c('v-col',{staticClass:"pa-0 pa-md-4",attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-card',{staticClass:"pt-0"},[_c('v-card-title',{staticClass:"d-flex justify-center"},[_vm._v(" Suivi d'intervention ")]),_c('v-card-subtitle',{staticClass:"d-flex justify-center"},[_vm._v(" Description: "+_vm._s(_vm.ticket && _vm.ticketName)),_c('br'),_vm._v("Réference: "+_vm._s(_vm.reference)+" "),_c('v-col',{attrs:{"cols":"1"}},[(_vm.isDirectLink && _vm.isCookie)?_c('v-btn',{staticClass:"ml-6 mt-n4",attrs:{"small":"","dark":"","color":"white","href":_vm.listInter,"target":"_blank","title":"Liste des interventions"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-clipboard-list")])],1):_vm._e()],1)],1),_c('v-card-subtitle',{staticClass:"d-flex justify-center mt-n6"},[_vm._v(" "+_vm._s(_vm.contact)+" ")]),_c('v-card-text',{attrs:{"pa-2":""}},[_c('ticket-action',{attrs:{"ticket-id":Number.parseInt(_vm.ticketId),"entity-id":_vm.ticket.entities_id,"ticket-status":_vm.ticket.status},on:{"newComment":_vm.addNewITILFollowup,"newSolution":_vm.addNewITILSolution,"newTask":_vm.addNewTask}}),(_vm.list.length > 0)?_c('time-line',{attrs:{"list":_vm.list,"selected-ticket":_vm.ticket
                ? {
                    name: _vm.ticket.name,
                    date_creation: _vm.ticket.date,
                    content: _vm.ticket.content
                  }
                : null}}):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"list-item-avatar-three-line"}}):_vm._e(),(_vm.loaded && _vm.list.length === 0)?_c('div',{staticClass:"d-flex flex-column"},[_c('v-timeline',{staticClass:"timeline__wrapper",attrs:{"dense":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-timeline-item',{staticClass:"text-right",attrs:{"left":"","color":"warning","icon":""}},[_c('template',{slot:"icon"},[_c('v-icon',{staticClass:"white--text",attrs:{"x-large":""}},[_vm._v("mdi-information-outline")])],1),_c('span',{attrs:{"slot":"opposite"},slot:"opposite"},[_c('v-chip',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("access_time ")]),_vm._v(" "+_vm._s(_vm.moment(_vm.ticket.date_creation).format("DD/MM/YYYY HH:mm"))+" ")],1)],1),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"ma-2 success--text text-justify incident description pb-2 pt-2",attrs:{"color":"success","outlined":"","x-large":""}},[_vm._v(" Déclaration d'intervention: "),(_vm.ticket.content)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.decodeContent(_vm.ticket.content))}}):_vm._e()])])],2),_c('v-timeline-item',{attrs:{"right":"","color":"grey","icon":""}},[_c('template',{slot:"icon"},[_c('v-icon',{staticClass:"white--text",attrs:{"x-large":""}},[_vm._v("mdi-information-outline")])],1),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"grey","outlined":""}},[_vm._v(" Aucun élement de suivi ")])],2)],1)],1):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }